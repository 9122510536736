@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?44308393');
  src: url('../fonts/fontello.eot?#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff?44308393') format('woff'),
       url('../fonts/fontello.ttf?44308393') format('truetype'),
       url('../fonts/fontello.svg?44308393#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*=" icon-"] {
  font-family: 'fontello';
  font-style: normal;
  font-size: 2rem;
  speak: none;
}

.icon-adult:after { content: ""; }
.icon-location:after { content: ""; }

.requirements {
  list-style: none;
  text-align: center;
}

.requirements div {
  display: inline-block;
}

.requirements button {
  background-color: #f7f7f7;
  color: #a7a7a7;
  margin-top: 0;
  margin-bottom: 0;
  margin: 1rem;
  width: 5rem;
  height: 5rem;
  position: relative;
  text-align: center;
  line-height: 5rem;
  border-radius: 40%;
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
  border-width: 0px;
  outline: none;
  padding: 0;
}

.requirements button:hover{
  text-decoration: none;
  color: #555;
  background: #f5f5f5;
}

.requirements button:before {
  content: "";
  display: block;
  background: #fff;
  border-top: 2px solid #ddd;
  position: absolute;
  top: -18px;
  left: -18px;
  bottom: -18px;
  right: -18px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: inset 0px 8px 48px #ddd;
}

.fullfilled {
    color: #dd5337 !important;
}

.why-link{
    margin-top: 0px;
}

.why-link a {
    text-decoration: none;
    color: #0056b3;
}

.why-link a:hover {
    text-decoration: underline;
    font-weight: 700;
}