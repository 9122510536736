body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "monospace", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f9; */
  color: black;
  font-family: monospace;
  background: #F7F7F7 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEEAYAAAD5YUI9AAAACXBIWXMAAA4mAAAOJgGi7yX8AAAARElEQVQIHWPYyrxTed9eg3UQWkQHQgtaQGg5JgaohAdUoglC8z+A0mFQBYIpUIEXULoOQgvIQRXIBUElMqASShBaWQwApNg4NPAzQGwAAAAASUVORK5CYII=) repeat center top;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.554e0257.eot);
  src: url(/static/media/fontello.554e0257.eot?#iefix) format('embedded-opentype'),
       url(/static/media/fontello.fc17a889.woff) format('woff'),
       url(/static/media/fontello.0611ac15.ttf) format('truetype'),
       url(/static/media/fontello.528cda37.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*=" icon-"] {
  font-family: 'fontello';
  font-style: normal;
  font-size: 2rem;
  speak: none;
}

.icon-adult:after { content: "\E800"; }
.icon-location:after { content: "\F031"; }

.requirements {
  list-style: none;
  text-align: center;
}

.requirements div {
  display: inline-block;
}

.requirements button {
  background-color: #f7f7f7;
  color: #a7a7a7;
  margin-top: 0;
  margin-bottom: 0;
  margin: 1rem;
  width: 5rem;
  height: 5rem;
  position: relative;
  text-align: center;
  line-height: 5rem;
  border-radius: 40%;
  box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
  border-width: 0px;
  outline: none;
  padding: 0;
}

.requirements button:hover{
  text-decoration: none;
  color: #555;
  background: #f5f5f5;
}

.requirements button:before {
  content: "";
  display: block;
  background: #fff;
  border-top: 2px solid #ddd;
  position: absolute;
  top: -18px;
  left: -18px;
  bottom: -18px;
  right: -18px;
  z-index: -1;
  border-radius: 50%;
  box-shadow: inset 0px 8px 48px #ddd;
}

.fullfilled {
    color: #dd5337 !important;
}

.why-link{
    margin-top: 0px;
}

.why-link a {
    text-decoration: none;
    color: #0056b3;
}

.why-link a:hover {
    text-decoration: underline;
    font-weight: 700;
}

.no-margin {
    margin-top:0 !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}
.event-logo {
    width: 100%;
}

.rigthist h3 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
}

.description {
    font-size: 0.7rem;
}

.timer {
    font-size: 0.6rem;
}

.event-btn {
    width: 100%;
    height: 6rem;
    margin-bottom: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #ffffff;
    color: black;
    border-radius: 5px;
    border-width: thin;
    box-shadow: 0px 3px 8px #aaa;
}
.event-btn:hover {
    background-color: #008080;
}
.event-btn:disabled {
    background-color: #d6d8db;
    color: #383d41;
    border: none;
    border-color: none;
    box-shadow: none;
    opacity: 0.7;
}  
.event-list {
    background-color: #c7c7c9;
}

.done-screen {
    text-align: center;
    position: relative;
}

.done-screen p {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.done-screen h1 {
    -webkit-margin-before: 0.1em;
            margin-block-start: 0.1em;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    color: #dd5337;
    font-size: 3rem;
    font-weight: bold;
}

.twitter-icon{
    width: 40px;
    height: 40px;
}

.bold {
    font-weight: bold;
}

.centered {
    text-align: center;
    margin-top: 6.5rem !important;
    width: 100%;
    min-height: 200px; 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    background-color: #EEEEEE;
    box-shadow: 0px 4px 14px #ccc;
    display: inline-block;
}
.results--section {
    padding: 20px 0px;
    margin-top: 40px;
}
h2 {
    font-size: 25px;
    font-family: "Ubuntu", "monospace";
    font-weight: bold;
    margin-bottom: 0;
    margin-top: -5px;
}

.info-container {
    text-align: end;
}

.error {
    border: 1px solid transparent;
    background-color: #ffc107;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
}
.error:hover {
    background-color: #008080;
}  

.big-popup {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    top: 0; 
    left: 0;
    height: 100%;
    width: 100%;
}

.done {
    color: green;
}

.overlay {
    font-family: arial;
    color: #070707;
    text-align: left !important;
    margin-top: 10%;
    background-color: #DDDDDD;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.email {
    text-align: right !important;
}

.exit {
    font-size: 1.5rem;
    text-align: right !important;
    font-weight: bold; 
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 1rem;
    color: #dd5337;
}

.email a {
    text-decoration: none;
    color: #0056b3;
}

.email a:hover {
    text-decoration: underline;
    font-weight: 700;
}

.grid {
    display: grid;
    grid-template-columns:
    [real-full-start] 1fr [full-start] 2fr [content-start] 3fr [content-mid] 3fr [content-end] 2fr [full-end] 1fr [real-full-end];
    grid-gap: 1rem;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
}
.content {
    grid-column: content-start / content-end;
}
.full-content {
    grid-column: full-start / full-end;
    text-align: center;
    font-size: 0.7rem;
}
.real-full-content {
    grid-column: real-full-start / real-full-end;
    text-align: center;
}
.title {
    grid-column: full-start / full-end;
    text-align: center;
    margin: 0;
    padding-top: 0.5rem;
}
.title-bar {
    grid-column: full-start / full-end;
    text-align: center;
    margin: 0;
    padding-top: 0.5rem;
}
.left {
    grid-column: content-start  / content-mid;
}
.leftist {
    grid-column: real-full-start  / content-start;
    align-self: center; 
}
.rigthist {
    grid-column: content-start  / content-end;
    text-align: start;
}
.rigth {
    grid-column: content-mid  / content-end;
}
.left-half {
    grid-column: full-start  / content-mid;
}
.right-half {
    grid-column: content-mid  / full-end;
}
.rigth-margin {
    grid-column: content-end  / full-end;
}
.real-rigth-margin {
    grid-column: content-end  / real-full-end;
}
.all-grid {
    grid-column: real-full-start  / real-full-end;
}

