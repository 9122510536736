
.no-margin {
    margin-top:0 !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}
.event-logo {
    width: 100%;
}

.rigthist h3 {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
}

.description {
    font-size: 0.7rem;
}

.timer {
    font-size: 0.6rem;
}

.event-btn {
    width: 100%;
    height: 6rem;
    margin-bottom: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #ffffff;
    color: black;
    border-radius: 5px;
    border-width: thin;
    box-shadow: 0px 3px 8px #aaa;
}
.event-btn:hover {
    background-color: #008080;
}
.event-btn:disabled {
    background-color: #d6d8db;
    color: #383d41;
    border: none;
    border-color: none;
    box-shadow: none;
    opacity: 0.7;
}  
.event-list {
    background-color: #c7c7c9;
}