
.done-screen {
    text-align: center;
    position: relative;
}

.done-screen p {
    font-size: 0.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.done-screen h1 {
    margin-block-start: 0.1em;
    margin-block-end: 0;
    color: #dd5337;
    font-size: 3rem;
    font-weight: bold;
}

.twitter-icon{
    width: 40px;
    height: 40px;
}

.bold {
    font-weight: bold;
}

.centered {
    text-align: center;
    margin-top: 6.5rem !important;
    width: 100%;
    min-height: 200px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#e7e7e7));
    background-image: -webkit-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -moz-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -ms-linear-gradient(top, #f7f7f7, #e7e7e7); 
    background-image: -o-linear-gradient(top, #f7f7f7, #e7e7e7);
    background-color: #EEEEEE;
    box-shadow: 0px 4px 14px #ccc;
    display: inline-block;
}