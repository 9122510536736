.results--section {
    padding: 20px 0px;
    margin-top: 40px;
}
h2 {
    font-size: 25px;
    font-family: "Ubuntu", "monospace";
    font-weight: bold;
    margin-bottom: 0;
    margin-top: -5px;
}

.info-container {
    text-align: end;
}

.error {
    border: 1px solid transparent;
    background-color: #ffc107;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
}
.error:hover {
    background-color: #008080;
}  

.big-popup {
    position: fixed;
    background-color: rgba(0,0,0,0.7);
    top: 0; 
    left: 0;
    height: 100%;
    width: 100%;
}

.done {
    color: green;
}

.overlay {
    font-family: arial;
    color: #070707;
    text-align: left !important;
    margin-top: 10%;
    background-color: #DDDDDD;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
.email {
    text-align: right !important;
}

.exit {
    font-size: 1.5rem;
    text-align: right !important;
    font-weight: bold; 
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 1rem;
    color: #dd5337;
}

.email a {
    text-decoration: none;
    color: #0056b3;
}

.email a:hover {
    text-decoration: underline;
    font-weight: 700;
}

.grid {
    display: grid;
    grid-template-columns:
    [real-full-start] 1fr [full-start] 2fr [content-start] 3fr [content-mid] 3fr [content-end] 2fr [full-end] 1fr [real-full-end];
    grid-gap: 1rem;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
}
.content {
    grid-column: content-start / content-end;
}
.full-content {
    grid-column: full-start / full-end;
    text-align: center;
    font-size: 0.7rem;
}
.real-full-content {
    grid-column: real-full-start / real-full-end;
    text-align: center;
}
.title {
    grid-column: full-start / full-end;
    text-align: center;
    margin: 0;
    padding-top: 0.5rem;
}
.title-bar {
    grid-column: full-start / full-end;
    text-align: center;
    margin: 0;
    padding-top: 0.5rem;
}
.left {
    grid-column: content-start  / content-mid;
}
.leftist {
    grid-column: real-full-start  / content-start;
    align-self: center; 
}
.rigthist {
    grid-column: content-start  / content-end;
    text-align: start;
}
.rigth {
    grid-column: content-mid  / content-end;
}
.left-half {
    grid-column: full-start  / content-mid;
}
.right-half {
    grid-column: content-mid  / full-end;
}
.rigth-margin {
    grid-column: content-end  / full-end;
}
.real-rigth-margin {
    grid-column: content-end  / real-full-end;
}
.all-grid {
    grid-column: real-full-start  / real-full-end;
}
